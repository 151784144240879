<template>
  <div id="PaisSelect"
       class="maz-base-component maz-phone-number-input--lg maz-phone-number-input maz-flex">
    <MazSelect :options="countriesSorted"
               :search-placeholder="searchPlaceholder" :placeholder="placeholder" search
               ref="CountrySelector" :error="error"
                              :size="size"
               color="color"
  :loading="cargando"
               :success="isValid"
               :disabled="disabled"
               class="country-selector pais-selector"
               :items-height="countriesHeight"
               :position="position"
               :class="classPadding"
               
               v-model="selectValue"
               :config="Config">
      <template v-slot="{ option }">
        <div class="maz-flex maz-align-center">
          <div class="country-selector__flag-container maz-mr-2">
            <div :class="`maz-flag maz-flag-${option.iso2.toLowerCase()}`" />
          </div>
          <div class="maz-dots-text maz-flex-1 maz-text-left maz-text-color"
               :class="{
              'maz-text-white': option.isSelected
            }">
            {{ option.nombre }}
          </div>
        </div>
      </template>
    </MazSelect>
    <button v-if="selectValue" class="maz-phone-number-input__country-flag"
            tabindex="-1"
            @click="focusCountrySelector">
      <div :class="`maz-flag maz-flag-${iso2}`" />
    </button>
  </div>
</template>

<script>
  //  import countries from '@/api-services/phoneCodeCountries.js';
  import { countries, countriesIso } from '@/api-services/Paises.js'
  // console.debug(countries);
  import {  MazSelect } from 'maz-ui'
  export default {
    name: 'MainPaisSelect',
    components: {
      MazSelect
    },
    //    mixins: [uniqueId],
    props: {
      cargando: { type: Boolean, default: false },

      value: { type: String, default: null },
      id: { type: String, default: null },
      disabled: { type: Boolean, default: false },
      error: { type: Boolean, default: false },
      // set default country code (Ex: `default-country-code="FR"`)
      defaultCountryCode: { type: String, default: 'MX' },
      // Same as MazInput (options: `sm|md|lg`)
      size: { type: String, default: null },
      // Countries selected will be at the top of the list - Ex : `preferred-countries="['FR', 'BE', 'DE']`
      preferredCountries: {
        type: Array, default: () => ['MX', 'FR', 'BE', 'DE'] },
      // Only countries selected are in list - Ex : `only-countries="['FR', 'BE', 'DE']`
      onlyCountries: { type: Array, default: null },
      // Countries seleted are remove from the list - Ex : `ignored-countries="['FR', 'BE', 'DE']`
      ignoredCountries: { type: Array, default: Array },
      // Translate text in component - By default `{ countrySelectorLabel: 'Country code', countrySelectorError: 'Choose country', phoneNumberLabel: 'Phone number', example: 'Example:' }`
      translations: { type: Object, default: null },
      // Remove the validation UI state (success border color)
      noValidation: { type: Boolean, default: false },
      // Remove flags in country selector
      noFlags: { type: Boolean, default: false },
      // Remove the number example from the label input
      noExample: { type: Boolean, default: false },
      // Change the height of country item in list
      countriesHeight: { type: Number, default: 30 },
      // The select has no label in the input
      placeholder: { type: String, default: 'País' },
      searchPlaceholder: { type: String, default: 'Buscar país' },
      // Enable the dark mode
      dark: { type: Boolean, default: false },
      // Use color
      color: { type: String, default: 'primary' },

      campoEtiqueta: { type: String, default: 'nombre' },
      campoValor: { type: String, default: 'iso2' },

      // set the position of countries list (ex: `top`, `top right`, `bottom right`)
      position: { type: String, default: 'left bottom' },
    },
    data() {
      return {
        datos: countries,
        results: {},
        countryCode: this.defaultCountryCode,

        selectValue: this.defaultCountryCode,
      }
    },
    computed: {
      classPadding() {

        return { 'no-padding-left': this.selectValue == null || this.selectValue == "" };
      },

      iso2() {
        if (this.selectValue.length > 2) {
          var R = countries.filter(item => item.iso3 == this.selectValue);
          //console.debug(R);
          return R[0].iso2.toLowerCase();
        }
        return this.selectValue.toLowerCase();
      },
      Config() {
        {
          return {
            labelKey: this.campoEtiqueta,
            searchKey: this.campoEtiqueta,
            valueKey: this.campoValor
          };
        }
      },
      isValid() {
        return this.results.isValid
      },
      t() {

        return {
          ...locales,
          ...this.translations
        }
      },
      LosDatos() {
       // console.debug(countries);
        return countries;
      },
      // Countries list management
      countriesList() {
        return countries.filter(item => !this.ignoredCountries.includes(item.iso2))
      },
      countriesFiltered() {
        const countries = this.onlyCountries || this.preferredCountries
        return countries.map(country => this.countriesList.find(item => item.iso2.includes(country)))
      },
      otherCountries() {
        return this.countriesList.filter(item => !this.preferredCountries.includes(item.iso2))
      },
      countriesSorted() {
        return this.preferredCountries
          ? [
            ...this.countriesFiltered,
            ...this.otherCountries
          ]
          : this.onlyCountries
            ? this.countriesFiltered
            : this.countriesList
      }
    },
    watch: {
      value(valor) {
        
        this.selectValue = valor;
      },
      selectValue(valor) {
        console.debug(valor);
        //this.$emit('update:value', valor);
        this.$emit('input', valor);
      //  this.$set(this, 'value', valor);
       // this.value = valor;
      }
    },
    async mounted() {
      if (this.value != this.selectValue)
        this.selectValue = this.value;
    },
    methods: {
      async isCountryAvailable(locale) {
        try {
          if (countriesIso.includes(locale)) return true
          throw `MazPhoneNumberInput: The country ${locale} is not available`
        } catch (e) {
          throw new Error(e)
        }
      },
      async focusCountrySelector() {
        await this.$nextTick()
        this.$refs.CountrySelector.$el.querySelector('input').focus()
      },


    }
  }

</script>

<style scoped>
  .pais-selector {
    flex: 0 0 100% !important;
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }
</style>
