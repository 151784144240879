
import Base from '@/api-base/base'
const BaseURL = `CPsMexico`;
export default {

  gets(Vista, cp, OnCorrecto, OnErroneo, OnFinalizado) {

    return Base.get(Vista, `${Base.URL}/${BaseURL}`, { params: {CP:cp} },  OnCorrecto, OnErroneo, OnFinalizado);
  },
}
