<template>
  <div>


    <div class="row mb-2">
      <div class="col-4 mb-2 px-0">
        <MainPaisSelect :translations="MazPhoneNumber.Traduccion" :default-country-code="MazPhoneNumber.PaisPredeterminado"
                        :preferred-countries="MazPhoneNumber.PaisesPreferidos" v-model="Direccion.pais" position="top"
                        showCodeOnList no-validation :cargando="loading || cargando" :size="size"
                        :loading="loading || cargando" placeholder="País" />
      </div>


      <div class="col-8 mb-2 px-0">
        <div id="PaisSelect"
             class="maz-base-component ">
          
  <MazSearch v-if="EsMexico&&(Direccion.cp==null ||Direccion.cp=='')" v-model="DatosCP" 
             :items="CPs" :size="size"
             :loading="loading || cargando"
             :autocomplete="autocompleteCP"
             left-icon-name="search"
             placeholder="Código Postal"
             :no-data="!CPs && !CPs.length"
             itemText="cp"
             replaceOnSelect
             :list-width="250"
             @request="bucarCP"
             @input="seleccionadoCP">
    <template v-slot="{ item }">
      <div class="item maz-flex maz-align-center">
        <div>
          <h5 class="mb-0">{{ item.asentamiento }}-{{ item.tipoAsentamiento }}</h5>
          <small class="text-muted">{{ item.cp }}, {{ item.municipio }}, {{ item.estado }}, {{ item.ciudad }}</small>
        </div>
      </div>
    </template>
    <template v-slot:no-data>
      <div class="item maz-flex maz-flex-center">
        <p>No hay datos del código postal</p>
      </div>
    </template>
  </MazSearch>
    
        
          <MazInput v-else v-model="Direccion.cp" :autocomplete="autocompleteCP" clearable
                    placeholder="Código Postal" :size="size"
                    :loading="loading || cargando" />
        </div>
      </div>

    </div>

    <div class="row mb-2">
      <div class="col-8 mb-2 px-0">
        <MazInput v-model="Direccion.dirCalle"
                  placeholder="Calle" :size="size"
                  :loading="loading || cargando" />
      </div>
      <div class="col-2 mb-2 px-0">

        <MazInput v-model="Direccion.dirCalleNo"
                  placeholder="Número" :size="size"
                  :loading="loading || cargando" />
      </div>

      <div class="col-2 mb-2 px-0">
        <MazInput v-model="Direccion.dirInterior"
                  placeholder="Interior" :size="size"
                  :loading="loading || cargando" />
      </div>


    </div>

    <div class="row mb-2">
      <div class="col-6  mb-2 px-0">
        <MazInput v-model="Direccion.colonia"
                  :placeholder="TipoAsentamiento" :size="size"
                  :loading="loading || cargando" />
      </div>
      <div class="col-6  mb-2 px-0">
        <MazInput v-model="Direccion.municipio"
                  placeholder="Municipio / Alcaldía" :size="size"
                  :loading="loading || cargando" />
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-6  mb-2 px-0">
        <MazInput v-model="Direccion.ciudad"
                  placeholder="Ciudad" :size="size"
                  :loading="loading || cargando" />
      </div>
      <div class="col-6  mb-2 px-0">
        <MazInput v-model="Direccion.estado"
                  placeholder="Estado" :size="size"
                  :loading="loading || cargando" />
      </div>
    </div>


  </div>
</template>

<script>
  import { MazSearch, MazInput } from 'maz-ui'
  import Base from '@/api-base/base';
  import cpMexico from '@/api-services/cpMexico.service';
  import MainPaisSelect from '@/Vistas-base/Main/PaisSelect.vue';


  export default {
    components: {
      MazSearch,
      MazInput,
      MainPaisSelect,
    },
    props: {
      loading: { type: Boolean, default: false },
      value: Object,
      defaultCountryCode: { type: String, default: 'MX' },
      // Same as MazInput (options: `sm|md|lg`)
      size: { type: String, default: null },
    },
    computed: {
      autocompleteCP() {
        return `${this.$options.name}-Cp${Math.floor(Math.random() * 1000)}`;
      },
      EsMexico() {
        return this.value != null && this.value.pais == 'MX';
      },
      MazPhoneNumber() {
        return Base.MazPhoneNumber;
      },

    },
    watch: {
      value(nuevoValor) {
        console.debug(nuevoValor);
        if (nuevoValor == null) {
          this.cargaVacio();
          this.$emit('input', this.Direccion);
        }
        else {
          if (nuevoValor.pais == null)
            nuevoValor.pais = 'MX';
          this.Direccion = nuevoValor;
          this.DatosCP = this.Direccion.cp;
        }
        //console.debug(this.datos);
      },
    },
    mounted() {
      if (this.value != null)
        this.Direccion = this.value;
    },
    data() {
      return {

        TipoAsentamiento: 'Colonia',
        Direccion: {},

        DatosCP: null,
        CPs: [],
        cargando: false,


        Etiqueta: '',

      };
    },
    methods: {
      cargaVacio() {
        this.Direccion = Object.assign({}, {
          "dirCalle": '',
          "dirCalleNo": '',
          "dirInterior": '',
          "colonia": '',
          "tipoAsentamiento": '',
          "municipio": '',
          "ciudad": '',
          "estado": '',
          "pais": "MX",
          "cp": '',
          "gps": ''
        });
      },
      seleccionadoCP(cp) {
        console.debug(cp);
        this.value.cp = cp.cp;
        this.value.ciudad = cp.ciudad;
        this.value.estado = cp.estado;
        this.value.colonia = cp.asentamiento;
        this.value.municipio = cp.municipio;
        this.value.tipoAsentamiento = cp.tipoAsentamiento;
        this.TipoAsentamiento = cp.tipoAsentamiento;
      },

      async bucarCP(query) {
        try {
          console.debug(query);
          cpMexico.gets(this, query, (response) => {
            this.CPs = response.data;
          });
        //  this.CPs = codigosPostalesFiltra(query);
          //  console.debug(this.CPs);
        } catch (err) {
          throw new Error(err)
        } finally {
        }
      },

    }
  };
</script>
<style src="maz-ui/lib/css/index.css"></style>
<style lang="scss" scoped>
  .item {
    height: 60px;
    img

  {
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }
  }
</style>
<style>
  .maz-border-radius {
    border-radius: 0.4285rem !important;
  }
</style>
