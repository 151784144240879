const paises = [
  "AF|AFG|Afganistán",
  "AL|ALB|Albania",
  "DE|DEU|Alemania",
  "AD|AND|Andorra",
  "AO|AGO|Angola",
  "AI|AIA|Anguila",
  "AQ|ATA|Antártida",
  "AG|ATG|Antigua y Barbuda",
  "SA|SAU|Arabia Saudita",
  "DZ|DZA|Argelia",
  "AR|ARG|Argentina",
  "AM|ARM|Armenia",
  "AW|ABW|Aruba",
  "AU|AUS|Australia",
  "AT|AUT|Austria",
  "AZ|AZE|Azerbaiyán",
  "BE|BEL|Bélgica",
  "BS|BHS|Bahamas",
  "BH|BHR|Bahrein",
  "BD|BGD|Bangladesh",
  "BB|BRB|Barbados",
  "BZ|BLZ|Belice",
  "BJ|BEN|Benín",
  "BT|BTN|Bhután",
  "BY|BLR|Bielorrusia",
  "MM|MMR|Birmania",
  "BO|BOL|Bolivia",
  "BA|BIH|Bosnia y Herzegovina",
  "BW|BWA|Botsuana",
  "BR|BRA|Brasil",
  "BN|BRN|Brunéi",
  "BG|BGR|Bulgaria",
  "BF|BFA|Burkina Faso",
  "BI|BDI|Burundi",
  "CV|CPV|Cabo Verde",
  "KH|KHM|Camboya",
  "CM|CMR|Camerún",
  "CA|CAN|Canadá",
  "TD|TCD|Chad",
  "CL|CHL|Chile",
  "CN|CHN|China",
  "CY|CYP|Chipre",
  "VA|VAT|Ciudad del Vaticano",
  "CO|COL|Colombia",
  "KM|COM|Comoras",
  "CG|COG|República del Congo",
  "CD|COD|República Democrática del Congo",
  "KP|PRK|Corea del Norte",
  "KR|KOR|Corea del Sur",
  "CI|CIV|Costa de Marfil",
  "CR|CRI|Costa Rica",
  "HR|HRV|Croacia",
  "CU|CUB|Cuba",
  "CW|CWU|Curazao",
  "DK|DNK|Dinamarca",
  "DM|DMA|Dominica",
  "EC|ECU|Ecuador",
  "EG|EGY|Egipto",
  "SV|SLV|El Salvador",
  "AE|ARE|Emiratos Árabes Unidos",
  "ER|ERI|Eritrea",
  "SK|SVK|Eslovaquia",
  "SI|SVN|Eslovenia",
  "ES|ESP|España",
  "US|USA|Estados Unidos de América",
  "EE|EST|Estonia",
  "ET|ETH|Etiopía",
  "PH|PHL|Filipinas",
  "FI|FIN|Finlandia",
  "FJ|FJI|Fiyi",
  "FR|FRA|Francia",
  "GA|GAB|Gabón",
  "GM|GMB|Gambia",
  "GE|GEO|Georgia",
  "GH|GHA|Ghana",
  "GI|GIB|Gibraltar",
  "GD|GRD|Granada",
  "GR|GRC|Grecia",
  "GL|GRL|Groenlandia",
  "GP|GLP|Guadalupe",
  "GU|GUM|Guam",
  "GT|GTM|Guatemala",
  "GF|GUF|Guayana Francesa",
  "GG|GGY|Guernsey",
  "GN|GIN|Guinea",
  "GQ|GNQ|Guinea Ecuatorial",
  "GW|GNB|Guinea-Bissau",
  "GY|GUY|Guyana",
  "HT|HTI|Haití",
  "HN|HND|Honduras",
  "HK|HKG|Hong kong",
  "HU|HUN|Hungría",
  "IN|IND|India",
  "ID|IDN|Indonesia",
  "IR|IRN|Irán",
  "IQ|IRQ|Irak",
  "IE|IRL|Irlanda",
  "BV|BVT|Isla Bouvet",
  "IM|IMN|Isla de Man",
  "CX|CXR|Isla de Navidad",
  "NF|NFK|Isla Norfolk",
  "IS|ISL|Islandia",
  "BM|BMU|Islas Bermudas",
  "KY|CYM|Islas Caimán",
  "CC|CCK|Islas Cocos (Keeling)",
  "CK|COK|Islas Cook",
  "AX|ALA|Islas de Åland",
  "FO|FRO|Islas Feroe",
  "GS|SGS|Islas Georgias del Sur y Sandwich del Sur",
  "HM|HMD|Islas Heard y McDonald",
  "MV|MDV|Islas Maldivas",
  "FK|FLK|Islas Malvinas",
  "MP|MNP|Islas Marianas del Norte",
  "MH|MHL|Islas Marshall",
  "PN|PCN|Islas Pitcairn",
  "SB|SLB|Islas Salomón",
  "TC|TCA|Islas Turcas y Caicos",
  "UM|UMI|Islas Ultramarinas Menores de Estados Unidos",
  "VG|VGB|Islas Vírgenes Británicas",
  "VI|VIR|Islas Vírgenes de los Estados Unidos",
  "IL|ISR|Israel",
  "IT|ITA|Italia",
  "JM|JAM|Jamaica",
  "JP|JPN|Japón",
  "JE|JEY|Jersey",
  "JO|JOR|Jordania",
  "KZ|KAZ|Kazajistán",
  "KE|KEN|Kenia",
  "KG|KGZ|Kirguistán",
  "KI|KIR|Kiribati",
  "KW|KWT|Kuwait",
  "LB|LBN|Líbano",
  "LA|LAO|Laos",
  "LS|LSO|Lesoto",
  "LV|LVA|Letonia",
  "LR|LBR|Liberia",
  "LY|LBY|Libia",
  "LI|LIE|Liechtenstein",
  "LT|LTU|Lituania",
  "LU|LUX|Luxemburgo",
  "MX|MEX|México",
  "MC|MCO|Mónaco",
  "MO|MAC|Macao",
  "MK|MKD|Macedônia",
  "MG|MDG|Madagascar",
  "MY|MYS|Malasia",
  "MW|MWI|Malawi",
  "ML|MLI|Mali",
  "MT|MLT|Malta",
  "MA|MAR|Marruecos",
  "MQ|MTQ|Martinica",
  "MU|MUS|Mauricio",
  "MR|MRT|Mauritania",
  "YT|MYT|Mayotte",
  "FM|FSM|Micronesia",
  "MD|MDA|Moldavia",
  "MN|MNG|Mongolia",
  "ME|MNE|Montenegro",
  "MS|MSR|Montserrat",
  "MZ|MOZ|Mozambique",
  "NA|NAM|Namibia",
  "NR|NRU|Nauru",
  "NP|NPL|Nepal",
  "NI|NIC|Nicaragua",
  "NE|NER|Niger",
  "NG|NGA|Nigeria",
  "NU|NIU|Niue",
  "NO|NOR|Noruega",
  "NC|NCL|Nueva Caledonia",
  "NZ|NZL|Nueva Zelanda",
  "OM|OMN|Omán",
  "NL|NLD|Países Bajos",
  "PK|PAK|Pakistán",
  "PW|PLW|Palau",
  "PS|PSE|Palestina",
  "PA|PAN|Panamá",
  "PG|PNG|Papúa Nueva Guinea",
  "PY|PRY|Paraguay",
  "PE|PER|Perú",
  "PF|PYF|Polinesia Francesa",
  "PL|POL|Polonia",
  "PT|PRT|Portugal",
  "PR|PRI|Puerto Rico",
  "QA|QAT|Qatar",
  "GB|GBR|Reino Unido",
  "CF|CAF|República Centroafricana",
  "CZ|CZE|República Checa",
  "DO|DOM|República Dominicana",
  "SS|SSD|República de Sudán del Sur",
  "RE|REU|Reunión",
  "RW|RWA|Ruanda",
  "RO|ROU|Rumanía",
  "RU|RUS|Rusia",
  "EH|ESH|Sahara Occidental",
  "WS|WSM|Samoa",
  "AS|ASM|Samoa Americana",
  "BL|BLM|San Bartolomé",
  "KN|KNA|San Cristóbal y Nieves",
  "SM|SMR|San Marino",
  "MF|MAF|San Martín (Francia)",
  "PM|SPM|San Pedro y Miquelón",
  "VC|VCT|San Vicente y las Granadinas",
  "SH|SHN|Santa Elena",
  "LC|LCA|Santa Lucía",
  "ST|STP|Santo Tomé y Príncipe",
  "SN|SEN|Senegal",
  "RS|SRB|Serbia",
  "SC|SYC|Seychelles",
  "SL|SLE|Sierra Leona",
  "SG|SGP|Singapur",
  "SX|SMX|Sint Maarten",
  "SY|SYR|Siria",
  "SO|SOM|Somalia",
  "LK|LKA|Sri lanka",
  "ZA|ZAF|Sudáfrica",
  "SD|SDN|Sudán",
  "SE|SWE|Suecia",
  "CH|CHE|Suiza",
  "SR|SUR|Surinám",
  "SJ|SJM|Svalbard y Jan Mayen",
  "SZ|SWZ|Swazilandia",
  "TJ|TJK|Tayikistán",
  "TH|THA|Tailandia",
  "TW|TWN|Taiwán",
  "TZ|TZA|Tanzania",
  "IO|IOT|Territorio Británico del Océano Índico",
  "TF|ATF|Territorios Australes y Antárticas Franceses",
  "TL|TLS|Timor Oriental",
  "TG|TGO|Togo",
  "TK|TKL|Tokelau",
  "TO|TON|Tonga",
  "TT|TTO|Trinidad y Tobago",
  "TN|TUN|Tunez",
  "TM|TKM|Turkmenistán",
  "TR|TUR|Turquía",
  "TV|TUV|Tuvalu",
  "UA|UKR|Ucrania",
  "UG|UGA|Uganda",
  "UY|URY|Uruguay",
  "UZ|UZB|Uzbekistán",
  "VU|VUT|Vanuatu",
  "VE|VEN|Venezuela",
  "VN|VNM|Vietnam",
  "WF|WLF|Wallis y Futuna",
  "YE|YEM|Yemen",
  "DJ|DJI|Yibuti",
  "ZM|ZMB|Zambia",
  "ZW|ZWE|Zimbabue",
];
export const countries = paises.map((country) => {
  var state_arr = country.split("|");
  return { iso2: state_arr[0], iso3: state_arr[1], nombre: state_arr[2]};
});
export const countriesIso = countries.map(country => country.iso2.toUpperCase());


